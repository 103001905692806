import React from 'react';

export default {
    social: {
        title: 'Социальный',
        lvls: [
            60,
            100,
            160,
            250,
            350,
            500,
            725
        ],
        text: <span>
            Социальный статус даётся за разнообразную общую деятельность на Портале <br /><br />
            Социальный статус получают участники и организаторы мероприятий, руководители групп и школ,
            участники конкурсов и различной активности на портале. <br/><br/>
            В том числе начальное количество очков даётся за оформление своего профиля (указание контактных данных,
            установку аватарки и т.д.) <br/><br/>
            Чем больше Портал будет развиваться в плане развлекательного контента, тем больше различных возможностей
            по получению очков социального статуса будет у пользователей.
        </span>
    },
    judge: {
        title: 'Судейский',
        lvls: [
            50,
            100,
            130,
            210,
            350,
            500
        ],
        text: <span>
            Судейский статус присуждается за участие в качестве судьи на различных соревнованиях, за участие 
            в судейских семинарах. <br /><br />
            Судейский статус не является зеркалом судейской категории, а скорее отображает деятельность на 
            Портале в области судейства.
        </span>
    },
    bet: {
        title: 'Прогонозы',
        lvls: [
            50,
            100,
            160,
            250,
            400,
            625,
            900
        ],
        text: <span>
            Статус прогнозиста даётся за участие в конкурсах прогнозов на соревнованиях. <br/><br/>
        </span>
    },
    challenge: {
        title: 'Челленджи',
        lvls: [
            50,
            70,
            120,
            200,
            350,
            500
        ],
        text: <span>
            Статус участия в челленджах. <br/> 
            Челленджи - это отдельная деятельность на портале, связанная с участием в online мероприятиях и всех активностях, связаанных с карточками из раздела Техника  <br/>
        </span>
    },
    snowman: {
        title: 'Снеговики',
        lvls: [
            15,
            100,
            150,
            200,
            250,
            300
        ],
        text: <span>
            Это ваш опыт в игре на островах Снеговики. <br/> 
            Отображается непосредственно в игре как опыт и уровень Вашего персонажа  <br/>
        </span>
    },
    default: {
        lvls: [
            50,
            100,
            130,
            210,
            350,
            500
        ],
        text: <span>
            Для данной категории не готово описание
        </span>
    }
}