import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Link } from 'react-router-dom';

import './style.css';

/**
  Это маленький список новостей
  Сюда приходит массив новостей News ([ {...News}, ... ])
 */

class OwlQuestPlashka extends PureComponent {

    state = {
        loadingQuest: null
    }

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount = async () => {
    }

    render() {

        const { upperStyle, client } = this.props;
        const { width, height } = upperStyle;

        return <Link to={'/event/407'}>
            <div style={{ width, height }} className='owl_quest_plashka'>
                {/* <img src="/../images/ver2/owl_quest.jpg" /> */}
                {/* <img src="/../images/ver2/quiz_rk2024.jpg" /> */}
                <img src="/../images/ver2/solo_second.jpg" />
            </div>
        </Link >
    }

}

export default withCapoeiraSportState('client')(OwlQuestPlashka);