
import axios from 'axios';

async function smConstructor({ ActionData = 'get', 
    ItemsId, CaveId, EnterCaveId, NowLut, History, NowHP, NowTime, Data, NowBlockId, IsPassed, ExpValue, ItemsToServer }) {
    let uri = 'https://' + this.ServerUrl + `/smConstructor`;

    let bodyFormData = new FormData();
    if (ActionData) bodyFormData.set('ActionData', ActionData);
    if (CaveId) bodyFormData.set('CaveId', CaveId);
    if (EnterCaveId) bodyFormData.set('EnterCaveId', EnterCaveId);
    if (NowLut) bodyFormData.set('NowLut', JSON.stringify(NowLut));
    if (History) bodyFormData.set('History', JSON.stringify(History));
    if (ItemsId) bodyFormData.set('ItemsId', JSON.stringify(ItemsId));

    if (ItemsToServer) bodyFormData.set('ItemsToServer', JSON.stringify(ItemsToServer));
    if (NowHP) bodyFormData.set('NowHP', NowHP);
    if (NowTime) bodyFormData.set('NowTime', NowTime);
    if (NowBlockId) bodyFormData.set('NowBlockId', NowBlockId);
    if (IsPassed) bodyFormData.set('IsPassed', IsPassed);
    if (ExpValue) bodyFormData.set('ExpValue', ExpValue);

    if (Data) bodyFormData.set('Data', JSON.stringify(Data));
    bodyFormData.set('sessionId', this.lastSessionId);

    console.log('smConstructor', bodyFormData);

    let res;

    try {
        res = await axios({
            method: 'post',
            url: uri,
            data: bodyFormData,
            config: {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            },
            timeout: 2500
        });
    } catch (err) {
        console.log('server error', err);
        return {};
    }


    console.log('smConstructor res', res);

    if (res && res.data && res.data.result && res.data.result.data && res.data.result.status === "Sucess") {
        return res.data.result.data;
    }

    return {};
}

export default {
    smConstructor
}
