import React from 'react';
import { Link } from 'react-router-dom';

export default {
    tri: {
        title: 'Базовый ресурс',
        text: <span>
            Базовые ресурсы даются за разнообразную общую деятельность на Портале <br /><br />
            На Базовые ресурсы Вы можете приобретать разлиные косметические предметы для украшения собственной странички
            <br />
            <a href='/shop/1'>Перейти в магазин</a>
        </span>
    },
    squ: {
        title: 'Высокий ресурс',
        text: <span>
            Высокие ресурсы даются за продвинутую деятельность на Портале <br /><br />
            На Базовые ресурсы Вы можете приобретать разлиные усовершенствованные косметические предметы для украшения собственной странички
            <br />
            <a href='/shop/1'>Перейти в магазин</a>
        </span>
    },
    pen: {
        title: 'Ивентный ресурс',
        text: <span>
            Ивентные ресурсы даются за участие в различных онлайн активностях Портала <br /><br />
            На Ивентные ресурсы Вы можете приобретать разлиные предметы, которые входят в продвинутые коллекции предметов в Достижениях
            <br />
            <a href='/shop/1'>Перейти в магазин</a>
        </span>
    },
    gek: {
        title: 'Донатный ресурс',
        text: <span>
            Донатные ресурсы получаются за материальную поддержку Портала <br /><br />
            На Донатные ресурсы Вы можете приобретать индивидуальные косметические предметы для украшения собственной странички, которые невозможно
            приобрести за стандартные ресурсы<br /><br />
            Администрация Портала невероятно благодарна людям, совершающим материальную поддержку, она очень помогает в развитии проекта CapoeiraSport и даёт понять,
            что аудитория ценит вложенный сюда труд
            <br />
            <a href='/shop/4'>Перейти в магазин</a>
        </span>
    },

    bra: {
        title: 'Бразилусы',
        dontMainShow: true,
        text: <span>
            Ресурс в рамках тестового мероприятия <br />
            Вы можете купить на него предметы в специальном магазине <br />
            <a href='/shop/2'>Перейти в магазин</a>
        </span>
    },

    kmo: {
        title: 'Кобук',
        dontMainShow: true,
        text: <span>
            Ресурс в рамках специального ивента в честь Кубка Москвы по капоэйра 2022.
            <br />
            <a href='/shop/5'>Перейти в ивент</a>
        </span>
    },
    spr: {
        title: 'Очки',
        dontMainShow: true,
        text: <span>
            Очки за дополнительные задания, зарабатываемые в рамках Весеннего челленджа 2023.
            <br />
            <a href='/event/219'>Перейти в ивент</a>
        </span>
    },
    spw: {
        title: 'Лист победы',
        text: <span>
            Победые очки за завершение Весеннего челленджа 2023.<br />
            На них можно приобрести специальные предметы в магазине Челленджа
            <br />
            <a href='/shop/8'>Перейти в магазин</a>
        </span>
    },
    is2: {
        title: 'Звездочка',
        dontMainShow: true,
        text: <span>
            Очки в рамках Летнего Лагеря Самара 2023.
        </span>
    },
    otz: {
        title: 'Отжимания',
        dontMainShow: true,
        text: <span>
            Выполнение отжиманий в рамках массового ивента
            <br />
            <a href='/event/302'>Перейти в ивент</a>
        </span>
    },
    pre: {
        title: 'Пресс',
        dontMainShow: true,
        text: <span>
            Выполнение пресса в рамках массового ивента
            <br />
            <a href='/event/302'>Перейти в ивент</a>
        </span>
    },
    sll: {
        title: 'Серебряный лайк',
        text: <span>
            Чуть более крутое выражение респекта!<br />
            Это лайки, которые есть у Вас в наличии, можете ставить их другим пользвателям
        </span>
    },
    gll: {
        title: 'Золотой лайк',
        text: <span>
            Невероятное выражение респекта!<br />
            Это лайки, которые есть у Вас в наличии, можете ставить их другим пользвателям
        </span>
    },
    pmc: {
        title: 'Тыквы',
        text: <span>
            Тыквы в рамках ивента HelloWeen 2024. На них можно купить предмет в магазине
            <br />
            <a href='/shop/12'>Перейти в магазин</a>
        </span>
    },

    
    sn1: {
        title: 'Синие снежинки',
        dontMainShow: true,
        text: <span>
            Синие снежинки в игре Снеговики
            <br />
        </span>
    },
    
    sn2: {
        title: 'Фиолетовые снежинки',
        dontMainShow: true,
        text: <span>
            Фиолетовые снежинки в игре Снеговики
        </span>
    },

    sn3: {
        title: 'Зелёные снежинки',
        dontMainShow: true,
        text: <span>
            Зелёные снежинки в игре Снеговики
        </span>
    },

    sn4: {
        title: 'Красные снежинки',
        dontMainShow: true,
        text: <span>
            Красные снежинки в игре Снеговики
        </span>
    },

    default: {
        title: 'Неизвестный ресурс',
        dontMainShow: true,
        text: <span>
            Для данной категории не готово описание
        </span>
    }
}