import React from 'react';

import { cloneDeep } from 'lodash';

/**
 *  приходит связка строкой и мы её переводим в массив типов 
 * */
export const getSequenceToFixed = ({ Sequence, Options, elements_only }) => {

  let fixed = [];

  const Sequence_arr = Sequence.split('/');

  for (let i = 0; i < Sequence_arr.length; i++) {
    if (Sequence_arr[i].substring(0, 3) === 'ty_') {
      fixed.push({
        type: Sequence_arr[i].substring(3)
      })
    }

    if (Sequence_arr[i].substring(0, 3) === 'el_') {
      fixed.push({
        element: Sequence_arr[i].substring(3)
      })
    }

    if (elements_only) {
      fixed.push({
        element: Sequence_arr[i]
      })
    }

  }

  const Options_arr = Options.split('/');

  for (let i = 0; i < Options_arr.length; i++) {
    if (Options_arr[i].substring(0, 3) === 'st_') {


      // берем остаток типа 2*round
      const option = Options_arr[i].substring(3);

      // сплитим, в option_arr[0] мы получаем номер элемента (0, 1, 3... и т.д.)
      const option_arr = option.split('*');

      for (let j = 1; j < option_arr.length; j++) {
        fixed[option_arr[0]].subtype = option_arr[j]
      }

    }
  }

  return fixed;

}


// отправляем сюда связку, он берёт из неё саму связку и Options (куда пишутся штрафы)
export const parseSEquenceOptions = ({ sequence }) => {


  const { Sequence, Options } = sequence;

  const sequence_elements = Sequence.split('/');
  const sequence_options_ = Options.split('/');

  let sequence_options = {};
  for (let i = 0; i < sequence_options_.length; i++) {
    const seq_opt = sequence_options_[i];
    if (seq_opt && seq_opt.substring(0, 3) === 'en_') {
      const parts = seq_opt.split('*');
      const part_element = parts[0];
      const part_scores = parts[1];
      if (part_element && part_scores) {

        const el_number = Number(part_element.substring(3));
        const el_scores = Number(part_scores);
        sequence_options[el_number] = el_scores;

      }
    }
  }

  return {
    sequence_elements,
    sequence_options
  }

}

// score - это оценка судьи типа 0,5 0,75 1 и т.д.
const returnPointKoefByJudgeScore = (score) => {
  if (score <= 0.5) return 0;
  if (score <= 0.6) return 0.1;
  if (score <= 0.65) return 0.15;

  if (score <= 0.7) return 0.25;
  if (score <= 0.75) return 0.35;
  if (score <= 0.8) return 0.4;
  if (score <= 0.85) return 0.5;
  if (score <= 0.9) return 0.8;
  if (score <= 0.95) return 0.9;

  return score;
}

// вы возвращаем массив с оценками за каждый элемент. Это если нам пришли два массива
// sequence_elements- это массив элементов [armada, macaco...]
// scores_element - это массив оценок верхних [1.5, 0.75, 1 ...]
// scores_seq - это массив оценок нижних [ 4.5, 3, 3.5 ... ]
export const getElementsScoresByJudging_arrays = ({ client, sequence_elements, scores_element, scores_seq, sequence_options, only_minus_error, noWeakElement }) => {

  let element_scores = []; // просто чтобы была размерность
  let PointsSum = 0;

  let weakElementIndex = 0;

  // сначала считаем базовые очки
  for (let i = 0; i < sequence_elements.length; i++) {

    element_scores.push({
      element_key: sequence_elements[i],
      Points: 0,
      BasicPoints: client.getElement(sequence_elements[i]).Points,
      ErrorPoints: sequence_options[i] || 0,
      PerformPoints: 0,
      SequencePoints: 0,
      isWeak: false
    });

    // штрафные очки - это очки, заменяющие базовые очки
    // scores_element - это оценка судьи, типа 1,1 0,8
    if (!element_scores[i].ErrorPoints) {
      // выдаём элементу его очки за технику
      element_scores[i].PerformPoints = element_scores[i].BasicPoints * returnPointKoefByJudgeScore(scores_element[i]);
    } else {

      // иногда нам нужно, чтобы мы смотрели штрафные очки ТОЛЬКО минусовые, т.е. меньше базовых
      // это когда у нас в раунде например Tier1 техника 200%, а в статистику нам это не надо
      if (only_minus_error && (element_scores[i].ErrorPoints > element_scores[i].BasicPoints)) {
        element_scores[i].PerformPoints = element_scores[i].BasicPoints * returnPointKoefByJudgeScore(scores_element[i]);
      } else {
        element_scores[i].PerformPoints = element_scores[i].ErrorPoints * returnPointKoefByJudgeScore(scores_element[i]);
      }
    }

    element_scores[i].Points = element_scores[i].PerformPoints;
    PointsSum += element_scores[i].PerformPoints;

    if (element_scores[i].Points <= element_scores[weakElementIndex].Points) {
      weakElementIndex = i;
    }

  }

  if (!noWeakElement) {
    element_scores[weakElementIndex].isWeak = true;
    element_scores[weakElementIndex].Points = element_scores[weakElementIndex].Points * 0.3;
    element_scores[weakElementIndex].ErrorPoints = element_scores[weakElementIndex].Points;
  }
  // ТУТ МЫ РАССТАВЛЯЕМ ОЦЕНКИ ЗА СВЯЗНОСТЬ

  let seqKoef = 0.3;//0.28; // хитрый коэффициент
  const downed = sequence_elements.length - 4; // сбавляем после четырёх элементов
  if (downed > 0) seqKoef -= 0.02 * downed;
  // а дальше мы наращиваем его за счет максимума элементов(плавающае 0.8 баллов)
  const awaragePoint = PointsSum / element_scores.length;
  // наращиваем, но не более 10 баллов
  seqKoef += 0.01 * Math.min(10, awaragePoint);

  const koefOld = 1; // поставить 10, если вдруг дело со сторыми связками, где связность была 0,1 - 0,5

  for (let i = 0; i < sequence_elements.length; i++) {
    // т.е. нулевой сиквенц не ставится
    if (i > 1) {
      // element_scores[i].SequencePoints = (element_scores[i].PerformPoints + element_scores[i - 1].Points) * scores_seq[i - 1] * 0.4;
      const Narost = ((scores_seq[i - 1] * koefOld * element_scores[i - 1].SequencePoints - 5) * seqKoef)
      element_scores[i].SequencePoints = scores_seq[i - 1] * koefOld + Narost;
    }
    // если единица, мы считаем нарост оценки как саму оценку
    if (i === 1) {
      element_scores[i].SequencePoints = scores_seq[i - 1] * koefOld;
    }
  }

  return element_scores;


}

// А это когда пришло с сервака мы преобразуем в два массива
export const getElementsScoresByJudgingOptions = ({ sequence_elements, JudgeOptions }) => {

  const Options_arr = JudgeOptions.split('/');
  let scores_element = [];
  let scores_seq = [];

  for (let i = 0; i < sequence_elements.length; i++) {
    scores_element.push(1);
    if (i < sequence_elements.length - 1) scores_seq.push(0);
  }

  for (let i = 0; i < Options_arr.length; i++) {
    if (Options_arr[i].substring(0, 3) === 'el_') {

      const el = Options_arr[i].substring(3);
      const el_arr = el.split('*');

      scores_element[Number(el_arr[0])] = Number(el_arr[1]);
    }

    if (Options_arr[i].substring(0, 3) === 'sq_') {

      const el = Options_arr[i].substring(3);
      const el_arr = el.split('*');

      scores_seq[Number(el_arr[0])] = Number(el_arr[1]);
    }
  }

  return { scores_seq, scores_element }

}

// выставляем звёзды связке на основе элементов связки и судейства
// scores_element - это оценки судьи
// scores_seq - это оценки связности
export const calculateStars = ({ scores_element = [], scores_seq = [], points }) => {
  let stars = {
    clean: 0,
    sequencity: 0,
    technics: 0,
    allresult: 0
  }

  // сначала смотрим финальные очки, делим на к-во элементов
  const _avarPoint = (points * 0.95) / (scores_element.length - 1);
  if (_avarPoint >= 3.5) stars.technics = 1;
  if (_avarPoint >= 5.5) stars.technics = 2;
  if (_avarPoint >= 7.7) stars.technics = 3;

  // теперь смотрим чистоту
  let isThereHigher1 = 0;
  let isThereLower1 = 0;
  let isThereLower095 = 0;
  let isThereLower9 = 0;
  let isThereLower85 = 0;
  for (let i = 0; i < scores_element.length; i++) {
    if (scores_element[i] > 1.05) isThereHigher1++;
    if (scores_element[i] < 1) isThereLower1++;
    if (scores_element[i] < 0.95) isThereLower095++;
    if (scores_element[i] < 0.9) isThereLower9++;
    if (scores_element[i] < 0.85) isThereLower85++;
  }

  if (!isThereLower85 && isThereLower9 < 2) { stars.clean = 1; }
  if (!isThereLower9 && isThereLower095 < 3) { stars.clean = 2 }
  if (!isThereLower1 && isThereHigher1 > 1) { stars.clean = 3; }

  // теперь смотрим связность
  let isThereHigher45 = 0;
  let isThereLower45 = 0;
  let isThereLower4 = 0;
  for (let i = 0; i < scores_seq.length; i++) {
    if (scores_seq[i] > 4.5) isThereHigher45++;
    if (scores_seq[i] < 4.5) isThereLower45++;
    if (scores_seq[i] < 4) isThereLower4++;
  }

  if (!isThereLower4) {
    stars.sequencity = 1;
  }

  if (!isThereLower4 && isThereLower45 < 2) {
    stars.sequencity = 2;
  }

  if (!isThereLower45 && isThereHigher45) {
    stars.sequencity = 3;
  }

  if ((stars.sequencity + stars.clean + stars.technics) >= 4) {
    stars.allresult = 1;
  }

  if ((stars.sequencity >= 1 && stars.clean >= 1 && stars.technics >= 1) &&
    ((stars.sequencity + stars.clean + stars.technics) >= 6)) {
    stars.allresult = 2;
  }

  if (
    (stars.sequencity >= 2 && stars.clean >= 2 && stars.technics >= 2) &&
    ((stars.sequencity + stars.clean + stars.technics) >= 8)
  ) {
    stars.allresult = 3;
  }

  return stars;

}


export const getCardKey = (card) => {

  let card_key = '';
  if (card.element) card_key = card.element;
  if (card.type) {
    card_key = `ty_${card.type}`;
  }
  if (card.subtype) {
    card_key = `st_${card.subtype}`;
  }

  if (card.type === 'any' && card.subtype === 'jump') card_key = 'st_any_jump';
  if (card.type === 'strike' && card.subtype === 'jump') card_key = 'st_strike_jump';
  if (card.type === 'movement' && card.subtype === 'jump') card_key = 'st_movement_jump';


  return card_key;

}

export const getSequenceName = ({ fixed, client }) => {

  let elements_names = [];
  for (let i = 0; i < fixed.length; i++) {
    elements_names.push(client.getCardName(fixed[i]))
  }
  return elements_names;

}

// обсчитывает одно правило
// возвращает структуру 
/* 
  {
    OnlyElements: {
      [key]: {
        Points
      }
    } || null
  }
*/
// НЕ УВЕРЕН, ЧТО ЭТО ИСПОЛЬЗУЕТСЯ ВООБЩЕ
export const getRules = (rule) => {

  const rule_index = rule.substring(0, 3);

  // all repeat
  if (rule_index === 'ar_') {
    return {

    }
  }

  // only elements
  if (rule_index === 'oe_') {

    const other_part = rule.substring(3);
    const elements = other_part.split('*');

    let result = {};

    for (let i = 0; i < elements.length; i++) {

      let element_key = elements[i];
      const first_part = elements[i].substring(0, 3);
      let points_part = 0;
      // значит там еще какие-то очки, там два регистра
      if (first_part === 'ep_') {
        points_part = Number(elements[i].substring(3, 5));
        element_key = elements[i].substring(5);
      }

      result[element_key] = { element_key, Points: points_part }

    }


    return {
      OnlyElements: result
    };
  }

}

/**
 *  Возвращает хитрый объект правил для всех Tier-ов, исходя из
 *  Rules (приходит строкой из соревнования или из Раунда)
 * 
 */
export const parseRules = (Rules) => {

  const defaultRules = {
    InSequence: { Tier: {} },
    Otkat: {
      // count
      // percent
      Tier: {}
    },
    Percents: { Tier: {} },
    OnlyElements: {}, // movement : [auusual, macaco]
    DenyElements: { Tier: {} },
    NeededElements: [], // [auusual, macaco]
    //MinPoints: 30 // очки при  
    //ElementsRepeat еще может быть
  }

  let finalRules = [cloneDeep(defaultRules), cloneDeep(defaultRules), cloneDeep(defaultRules), cloneDeep(defaultRules), cloneDeep(defaultRules)]
  // получили правила для каждого тира
  const parts = Rules.split('/');

  for (let i = 0; i < parts.length; i++) {

    let this_tier_rules = {};

    const part = parts[i]; // тут ti_2ti_3is_1*ti_1ep_20
    // для какого тира правила
    const to_tier = Number(part[3]); // тут 2
    const without_tier = part.substring(4); // тут ti_3is_1*ti_1ep_20
    const rules_to_tier = without_tier.split('*');

    for (let j = 0; j < rules_to_tier.length; j++) {
      // теперь мы начинаем правила смотреть по одному и кидать в тир нужный
      const rule_to_tier = rules_to_tier[j]; // тут типа ti_3is_1
      const tier = Number(rule_to_tier[3]); // тут 3
      const rule = rule_to_tier.substring(4); // тут is_1

      const rule_index = rule.substring(0, 3); // тут is_
      const rule_content = rule.substring(3);


      // это значит elements repeat
      if (rule_index === 'er_') {
        finalRules[to_tier].ElementsRepeat = Number(rule_content)
      }


      // это значит only_elements (приходит movement+auusual+macaco)
      if (rule_index === 'oe_') {

        const rule_content_array = rule_content.split('+');
        const rule_content_type = rule_content_array[0];
        const rule_content_elements = rule_content_array.splice(1);

        finalRules[to_tier].OnlyElements[rule_content_type] = cloneDeep(rule_content_elements);
      }

      // это значит needed_elements (приходит movement+auusual+macaco)
      if (rule_index === 'ne_') {

        const rule_content_array = rule_content.split('+');
        const rule_content_type = rule_content_array[0];
        const rule_content_elements = rule_content_array.splice(1);

        finalRules[to_tier].NeededElements = cloneDeep(rule_content_elements);
      }

      // это значит minimum points
      if (rule_index === 'mp_') {
        finalRules[to_tier].MinPoints = Number(rule_content);
      }

      // это значит in sequence
      if (rule_index === 'is_') {
        finalRules[to_tier].InSequence = {
          Tier: { [tier]: Number(rule_content) }
        }
      }

      // это значит percents
      if (rule_index === 'pr_') {
        finalRules[to_tier].Percents = {
          Tier: { [tier]: Number(rule_content) }
        }
      }

      // это значит elements point 
      if (rule_index === 'ep_') {
        if (Number(rule_content) === 0)
          finalRules[to_tier].DenyElements = {
            Tier: { [tier]: true }
          }
      }

      // это значит otkat 
      if (rule_index === 'ot_') {
        if (!finalRules[to_tier].Otkat.Tier[tier]) finalRules[to_tier].Otkat.Tier[tier] = {}
        finalRules[to_tier].Otkat.Tier[tier].count = Number(rule_content);
      }

      // это значит in otkat 
      if (rule_index === 'io_') {
        if (!finalRules[to_tier].Otkat.Tier[tier]) finalRules[to_tier].Otkat.Tier[tier] = {}
        finalRules[to_tier].Otkat.Tier[tier].percent = Number(rule_content);
      }

    }


  }

  return {
    ...finalRules[0],
    Tier: {
      1: { ...finalRules[1] },
      2: { ...finalRules[2] },
      3: { ...finalRules[3] },
      4: { ...finalRules[3] }
    }
  };

}

export const getRuleContent = (Rules, elements = {}) => {

  let content = [];
  const {
    InSequence,
    Otkat,
    Percents,
    OnlyElements,
    DenyElements,
    ElementsRepeat
  } = Rules;

  if (!Rules || Object.keys(Rules).length === 0) return content;

  if (ElementsRepeat) {

    const raz = ElementsRepeat > 1 ? 'раза' : 'раз';

    content.push(
      <div className='solo-onecompetition-block-rules-row'>
        {`В одной связке можно использовать один и тот же элемент ${ElementsRepeat} ${raz}`}
      </div>
    )

  }

  // сначала смотрим, есть ли вообще запрещенные элемент
  const keysOnlyElements = Object.keys(OnlyElements)
  if (keysOnlyElements.length) {
    for (let i = 0; i < keysOnlyElements.length; i++) {
      let elements_array = [];
      for (let j = 0; j < OnlyElements[keysOnlyElements[i]].length; j++) {
        if (elements[OnlyElements[keysOnlyElements[i]][j]])
          elements_array.push(' ' + elements[OnlyElements[keysOnlyElements[i]][j]].title + ' ')
      }
      if (keysOnlyElements[i] === 'strike') {
        content.push(
          <div className='solo-onecompetition-block-rules-row'>
            {`Из ударов можно использовать только: ${elements_array}`}
          </div>
        )
      }
      if (keysOnlyElements[i] === 'movement') {
        content.push(
          <div className='solo-onecompetition-block-rules-row'>
            {`Из перемещений можно использовать только: ${elements_array}`}
          </div>
        )
      }
      if (keysOnlyElements[i] === 'defence') {
        content.push(
          <div className='solo-onecompetition-block-rules-row'>
            {`Из защит можно использовать только: ${elements_array}`}
          </div>
        )
      }
    }

  }

  // сначала смотрим, есть ли вообще запрещенные элемент
  const keysDenyElements = Object.keys(DenyElements.Tier)
  if (keysDenyElements.length) {
    for (let i = 0; i < keysDenyElements.length; i++)
      content.push(
        <div className='solo-onecompetition-block-rules-row'>
          {`Запрещено использование элементов Tier${keysDenyElements[i]}`}
        </div>
      )
  }
  // смотрим какой общий откат данного ТИРА
  const keysPercents = Object.keys(Percents.Tier)
  if (keysPercents.length) {
    for (let i = 0; i < keysPercents.length; i++)
      content.push(
        <div className='solo-onecompetition-block-rules-row'>
          {`Элементы Tier${keysPercents[i]} приносят ${Percents.Tier[keysPercents[i]]}% очков`}
        </div>
      )
  }

  // смотрим сколько элементов данного тира можно использовать
  const keysInSequence = Object.keys(InSequence.Tier)
  if (keysInSequence.length) {
    for (let i = 0; i < keysInSequence.length; i++)
      content.push(
        <div className='solo-onecompetition-block-rules-row'>
          {`В каждой связке можно использовать максимум ${InSequence.Tier[keysInSequence[i]]} элементов Tier${keysInSequence[i]}`}
        </div>
      )
  }

  // смотрим сколько элементов данного тира можно использовать
  const keysOtkat = Object.keys(Otkat.Tier)
  if (keysOtkat.length) {
    for (let i = 0; i < keysOtkat.length; i++)
      if (Number(keysOtkat[i]) === 0) {

        if (Otkat.Tier[keysOtkat[i]].count === 0) {
          content.push(
            <div className='solo-onecompetition-block-rules-row'>
              {`Нельзя повторять ни один элемент за всё мероприятие`}
            </div>
          )
        } else
          content.push(
            <div className='solo-onecompetition-block-rules-row'>
              {`Откат каждого элемента - ${Otkat.Tier[keysOtkat[i]].count} раунда. Стоимость элемента в откате составляет ${Otkat.Tier[keysOtkat[i]].percent}%`}
            </div>
          )

      } else {

        if (Otkat.Tier[keysOtkat[i]].count === 0) {
          content.push(
            <div className='solo-onecompetition-block-rules-row'>
              {`Нельзя повторять ни один элемент из Teir${keysOtkat[i]} за всё мероприятие`}
            </div>
          )
        } else
          content.push(
            <div className='solo-onecompetition-block-rules-row'>
              {`Откат элементов Tier${keysOtkat[i]} - ${Otkat.Tier[keysOtkat[i]].count} раунда. Стоимость элемента в откате составляет ${Otkat.Tier[keysOtkat[i]].percent}%`}
            </div>
          )
      }
  }

  return content;

}